<template>
    <v-layout row wrap >
      <v-flex xs12 md12 class="ml-2">
          <div class="white mt-6 ">
                  <h1 class=" darken-4 brown--text text-center text--darken-4">
                      <b>Search result</b> 
                      <span v-if="searchFor == ''"> for "{{$route.params.search}}"</span>
                      <span v-else> for "{{searchFor}}"</span>

                     </h1>
                     
                     </div>
              <v-container row wrap mt-2>
                  <v-flex xs6 md6 ml-8>
                  <v-text-field
                      label="search"
                      placeholder="search"
                      outlined
                      color="brown darken-4"
                      append-icon="search"
                    
                      v-model="searchText"
              ></v-text-field>
               </v-flex>
               <v-flex md2 ml-5 xs4>
                  <v-btn block height="55" class="brown--text text--darken-4"  @click="search" outlined elevation="2"><b>search</b></v-btn>
               </v-flex>
               <v-spacer></v-spacer>
            </v-container>  
                         
            <v-flex xs12 md12>
              <div class="text-center" >
                  <v-progress-circular
                  v-if="loading"
                  :size="50"
                  color="primary"
                  indeterminate
                  ></v-progress-circular>
              </div> 
                <!-- <div class="ml-8">
             <h3 class="blue--text"> TOTAL SUBMISSIONS : <span class="black--text">{{users.records}}</span></h3> 
            </div>  -->
            <!-- <v-pagination 
             v-if="dateSorting"
             circle
              v-model="page"
              :length="pageLength"
              :total-visible="7"
              @input="gotoPage(page)"
              color="blue"
              ></v-pagination>    -->
          </v-flex> 
            <v-layout row-wrap class="ml-5 mr-3 ">
              <v-flex xs12 md12>
              <v-simple-table class="ma-2" fixed-header dense>
                      <template v-slot:default>
                      <thead >
                          <tr>
                          <th class="text-left brown darken-4 white--text">
                              #
                          </th>
                          <th class="text-left  brown darken-4 white--text">
                              ITEMS
                          </th>
                          <th class="text-left  brown darken-4 white--text">
                              PARTICIPANTS
                          </th>
                           <th class="text-left  brown darken-4 white--text">
                              VOTES
                          </th>
                          <th class="text-left  brown darken-4 white--text">
                              MOBILE
                          </th>
                          <th class="text-left  brown darken-4 white--text">
                              DISTRICT
                          </th>
                          <th class="text-left  brown darken-4 white--text">
                              DIVISION
                          </th>
                          <th class="text-left  brown darken-4 white--text">
                              INSTITUTION NAME
                          </th>
                          <th class="text-left  brown darken-4 white--text">
                              INSTITUTION ADDRESS
                          </th>
                          <th class="text-left  brown darken-4 white--text">
                              VIDEO TITLE
                          </th>
                          <th class="text-left  brown darken-4 white--text">
                              VIDEO LINKS
                          </th>
                          <th class="text-left  brown darken-4 white--text">
                              VIDEO STATUS
                          </th>
                          <th class="text-left  brown darken-4 white--text">
                              SUBMITTED DATE
                          </th>
                          <th class="text-left brown darken-4 white--text">
                              UPDATED DATE
                          </th>
                          <th v-if="ADMIN.role == 'SUPER ADMIN'" class="text-left brown darken-4 white--text">
                              VIDEO VERIFICATION
                          </th>
                          <th v-if="ADMIN.role == 'SUPER ADMIN'" class="text-left  brown darken-4 white--text">
                              ACTION
                          </th>
                          </tr>
                      </thead>
                      
                      <tbody >
                          <tr
                              v-for="(item,index) in users"
                              :key="index"
                              >
                          <td class="text-left black--text">{{ index+1 }}</td>
                          <td class="text-left black--text"><b>{{item.item.name}}</b></td>
                          <td class="text-left black--text" v-if="item.participant"> {{item.participant.name}}</td>
                          <td class="text-left black--text" v-else>NA</td>
                          <td class="text-left black--text" v-if="item.votes"><b>{{item.votes}}</b></td>
                          <td class="text-left black--text" v-else><b> 0</b></td>
                          <td class="text-left black--text"><b>{{item.participant.phone}}</b></td>
                          <td class="text-left black--text" v-if="item.participant">{{item.participant.address.district}}</td>
                          <td class="text-left black--text" v-else>NA</td>
                          <td class="text-left black--text" v-if="item.participant && item.participant.institution"> <b>{{item.participant.institution.division}}</b></td>
                          <td class="text-left black--text" v-else>NA</td>
                          <td class="text-left black--text" v-if="item.participant && item.participant.institution">{{item.participant.institution.institution_name}}</td>
                          <td class="text-left black--text" v-else>NA</td>
                          <td class="text-left black--text" v-if="item.participant && item.participant.institution">{{item.participant.institution.institution_address}}</td>
                          <td class="text-left black--text" v-else>NA</td>
                          <td class="text-left black--text">{{item.title}}</td>
                          <td class="text-left black--text"><v-btn small @click="showVideo(item.video_url)" dark class="brown darken-4" rounded>show video</v-btn></td>
                          <td class="text-left green--text" v-if="item.statusMsg == 'Submission Approved'" > <i><b>Approved</b> </i> </td>
                          <td class="text-left red--text" v-else-if="item.statusMsg == 'Submission rejected'" ><b>Rejected</b> </td>
                          <td class="text-left blue--text" v-else>Pending</td>
                          <td class="text-left black--text">{{item.created_on | moment("Do MMM YY, hh:mm a")}}</td>
                          <td class="text-left black--text">{{item.created_on | moment("Do MMM YY, hh:mm a")}}</td>
                          <td class="text-left black--text"  v-if="ADMIN.role == 'SUPER ADMIN'">
                              <v-btn @click="approveVideo(item._id)" class="green--text white my-1" rounded x-small> <b>Approve</b> </v-btn>
                              <v-btn @click="rejectVideo(item._id)" class="red--text my-1 white" rounded x-small> <b>Reject</b> </v-btn>
                          </td>
                          <td class="text-left black--text" v-if="ADMIN.role == 'SUPER ADMIN'" ><v-btn @click="deleteSubmission(item._id)" text ><v-icon color="red">mdi-delete</v-icon></v-btn> </td> 
                          
                      </tr>
                       </tbody>
                      </template>
                </v-simple-table>
                  <v-dialog
                      v-model="dialog"
                      max-width="1030"
                     max-height = "200"
                      v-if="option.url"
                      >
                      <v-row dense align="center" justify="center">
                          <v-col cols="12">
                              <v-card width="1000" class="black">
                                  <v-btn v-if="option.url" @click="closeVideo()" text>
                                      <v-icon  class="mr-3 mt-1 mb-2 red white--text" size="20">mdi-close</v-icon>
                                      <v-badge overlap  color="red"  class="mb-2" tile @click="closeVideo()" content="close video"></v-badge>
                                  </v-btn>
                              <Artplayer v-if="option.url" :option="option" :style="style" class="ml-5 " />
                           </v-card>
                          </v-col>
                          <!-- <v-col cols="1">
                              <v-btn v-if="option.url" @click="closeVideo()" class=" red--text darken-4 white" style="margin-left:-8px;" height="500" text><b><v-icon> mdi-close</v-icon> <p>close</p></b></v-btn>
                          </v-col> -->
                      </v-row>
                      
                  </v-dialog>
            </v-flex>
          </v-layout> 
                  
      </v-flex>
  </v-layout>

</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'
import { mapGetters } from 'vuex'
import Artplayer from "./ArtPlayer"


export default {
  data(){
      return {
          page:1,
          pageLength:1,
          offset :50,
          loading:false,
          dateSorting:true,
          searchFor:"",
          users : [],
          date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          menu: false,
          modal: false,
          menu2: false,
          dateOne: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          menuOne: false,
          modalOne: false,
          menu2One: false,
          searchText:"",
          option: {
          url:"",
          fullscreen: true,
          theme:"green",
          autoplay: true,
          aspectRatio: true,
          divisionValue :"",
          itemValue:""
            },
          style: {
              width: "auto",
              height: "500px",
              // margin : " 0 0 0 20px"
          },
          dialog: false,
          division: [
                  { title: 'Lower Primary' },
                  { title: 'Upper Primary' },
                  { title: 'High School' },
                  { title: 'Higher Secondary' },
                  { title: 'University' }
              ],
          events: [],
      }
  },
  async mounted(){
    {
        let {data}  = await Axios({
                        method : 'get',
                        url : BASE_URL +  "/search?search="+ this.$route.params.search +"&type=console",
                        headers: {
                            'Authorization': this.TOKEN
                        }
                        })
                     this.users = data.reverse()
                     }
                },
  computed:{
      ...mapGetters(['ADMIN','TOKEN'])
  },
  components: {
  Artplayer,
 
},
  methods : {
     gotoDetails(id){
      this.$router.push({name:'UserDetails',params:{id:id}})
     },
   
//   async deleteUser(id){
     
//   swal({
//                 title: "Are you sure?",
//                 text: "The Submissions will be removed permanantly!",
//                 icon: "warning",
//                 buttons: true,
//                 dangerMode: true,
//               })
//               .then( async (willDelete) => {
//                 if (willDelete) {
//                   let {data}  = await Axios({
//                       method : 'delete',
//                       url : BASE_URL + "/registration/delete/" + id,
//                       headers: {
//                           'Authorization': this.TOKEN
//                       }
//                   })
//                  await swal("Deleted", "Submissions removed successfully!", {
//                     icon: "success",
//                   });
                
//                   this.$router.go('')
//                 } else {
//                   swal("The Participant was not deleted!");
//                 }
//               })
              
    
//   },
  async deleteSubmission(id){
    
  swal({
                title: "Are you sure?",
                text: "The Submissions will be removed permanantly!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then( async (willDelete) => {
                if (willDelete) {
                  let {data}  = await Axios({
                      method : 'delete',
                      url : BASE_URL + "/submission/delete/" + id,
                      headers: {
                          'Authorization': this.TOKEN
                      }
                  })
                 await swal("Deleted", "Submissions removed successfully!", {
                    icon: "success",
                  });
                 
                  this.$router.go('')
                } else {
                  swal("The Submission was not deleted!");
                }
              })
              
    
  },

async search(){
   if(this.searchText){
    this.searchFor = this.searchText
    let {data}  = await Axios({
                        method : 'get',
                        url : BASE_URL +  "/search?search="+this.searchText +"&type=console",
                        headers: {
                            'Authorization': this.TOKEN
                        }
                        })
                        if(data.length> 0){
                            this.users = data.reverse()
                        }
                        else{
                        this.$toasted.error("Submission Not Found")
                            this.users = []
                        }
                      }
                else{
                    this.$toasted.error("Please enter any text to search")
                }
              },
   showVideo(link){
      this.option.url = ""
      this.option.url = link
      this.dialog = true
   },
   closeVideo(){
   this.option.url = ""
  //  this.$router.go('')
},    
async approveVideo(id){
  
  let {data}  = await Axios({
     
          method : 'post',
          url : BASE_URL +  "/submissions/update/" + id,
          headers: {
              'Authorization': this.TOKEN
          }
       })
       this.$router.go('')
},
async rejectVideo(id){

  let {data}  = await Axios({
          method : 'post',
          url : BASE_URL + "/submissions/rejected/" + id,
          headers: {
              'Authorization': this.TOKEN
          }
       })
       this.$router.go('')
},
async filterSubmission(){
  this.loading = true
  let  url = BASE_URL + "/submissions/filter"
  if(this.date <= this.dateOne){
      let payload = {previous : this.date,next : this.dateOne}
      let {data}  = await Axios.post(url,payload)
      this.users = data
      this.dateSorting = false
      this.loading = false
      this.page = 1
  }
  else{
      this.$toasted.error("From date must be less than To date")
  }
}

}
}
</script>

<style scoped>
 
thead{
  background-image: linear-gradient(266deg, #043946 0%, #8d71cf 100%);

  }
  .button{
      background-image: linear-gradient(266deg, #72680e 0%, #1f8ec5 100%);
  }
  .table {
background-color: red;}

</style>>


<template>
      <v-layout row wrap >
        <v-flex xs12 md12 class="ml-2">
            <div class="white mt-6 ">
                    <h1 class=" darken-4 blue--text text-center">
                        <b>SUBMISSION DETAILS</b> 
                       <p v-if="!dateSorting && !sort" style="font-size:22px" ><span class="mx-3">{{date | moment("Do MMM YY")}}</span> - <span class="mx-3">{{dateOne | moment("Do MMM YY")}}</span></p> 
                    </h1>
                </div>
                <v-container row wrap mt-2>
                    <v-flex xs6 md3 ml-8>
                    <v-text-field
                        label="Search Name"
                        placeholder="Search"
                        outlined
                        color="blue"
                        append-icon="search"
                        dense
                        v-model="searchText"
                ></v-text-field>
                 </v-flex>
                 <v-flex md1 ml-5 xs4>
                    <v-btn block height="40" class="blue--text" dark @click="search" outlined elevation="2" > <b >search</b></v-btn>
                 </v-flex>
                 <v-spacer></v-spacer>
                 
                 <v-flex md3 xs12 class="ml-12">
                    <v-row>
                        <v-col cols="4" sm="4" class="mx-2" 
                        >
                        <v-menu ref="menu"  v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto" 
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="date" label="From Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="blue" type="date">
                            </v-text-field>
                            </template>
                            <v-date-picker v-model="date" no-title scrollable color="blue">
                            <v-spacer></v-spacer>
                            <v-btn text color="blue" @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn text color="blue" @click="$refs.menu.save(date)" >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                        </v-col>
                       
                        <v-col cols="4" sm="4" class="mx-2"
                        >
                        <v-menu ref="menuOne"  v-model="menuOne" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateOne" label="To Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="blue" type="date">
                            </v-text-field>
                            </template>
                            <v-date-picker v-model="dateOne" no-title scrollable color="blue" >
                            <v-spacer></v-spacer>
                            <v-btn text color="blue" @click="menuOne = false">
                                Cancel
                            </v-btn>
                            <v-btn text color="blue" @click="$refs.menuOne.save(date)" >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                        </v-col>
                        <v-cols cols="4" sm="4" class="mt-6 mx-2">
                            <v-btn class="blue--text" elevation="2" @click="filterSubmission" outlined> <b>filter</b> </v-btn>
                        </v-cols>
                        </v-row>
                    </v-flex>
                </v-container> 
                           
              <v-flex xs12 md12>
                <div class="text-center" >
                    <v-progress-circular
                    v-if="loading"
                    :size="50"
                    color="primary"
                    indeterminate
                    ></v-progress-circular>
                </div> 
                  <div class="ml-8">
               <h3 class="blue--text"> TOTAL <span v-if="approvalStatus !=''">{{ approvalStatus }}</span> SUBMISSIONS  : <span class="black--text">{{users.records}}</span> </h3> 
              </div> 
              <v-pagination 
               v-if="dateSorting"
               circle
                v-model="page"
                :length="pageLength"
                :total-visible="7"
                @input="gotoPage(page)"
                color="blue"
                ></v-pagination>   
            </v-flex> 
              <v-layout row-wrap class="ml-5 mr-5 ">
                <v-flex xs12 md12>
                <v-simple-table class="ma-2" fixed-header dense>
                        <template v-slot:default>
                        <thead >
                            <tr>
                            <th class="text-left blue darken-2 white--text">
                                #
                            </th>
                            <th class="text-left blue darken-2 white--text">
                                 <v-menu offset-y >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        color="blue darken-2 "
                                        text
                                        >
                                      <span class="text-left blue darken-2 white--text"><b> ITEMS</b> <v-icon left top>mdi-menu-down</v-icon> </span> 
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                        v-for="(item, index) in events"
                                        :key="index"
                                        >
                                        <v-list-item-title><v-btn class="text-left blue--text" text @click="filterEvents(item._id)">{{ item.name}}</v-btn>
                                        </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                    </v-menu>
                               
                            </th>
                            <th class="text-left  blue darken-2 white--text">
                                PARTICIPANTS
                            </th>
                             <th class="text-left  blue darken-2 white--text">
                                <v-btn text small @click="sortVotes">
                                      <span class=" white--text"><b>VOTES</b> <v-icon left top v-if="sortVote">mdi-menu-down</v-icon> <v-icon left top v-else>mdi-menu-up</v-icon></span>
                                    </v-btn>
                            </th>
                            <th class="text-left  blue darken-2 white--text">
                                MOBILE
                            </th>
                            <th class="text-left  blue darken-2 white--text">
                                DISTRICT
                            </th>
                            <th class="text-left blue darken-2 white--text">
                                 <v-menu offset-y >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        color="blue darken-2 "
                                        text
                                        >
                                      <span class="text-left blue darken-2 white--text"><b> DIVISION</b> <v-icon left top>mdi-menu-down</v-icon> </span> 
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                        v-for="(item, index) in division"
                                        :key="index"
                                        >
                                        <v-list-item-title><v-btn class="text-left blue--text" text @click="filterDivision(item.title)">{{ item.title}}</v-btn>
                                        </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                    </v-menu>
                               
                            </th>
                            <!-- <th class="text-left  blue darken-2 white--text">
                                DIVISION
                            </th> -->
                            <th class="text-left  blue darken-2 white--text">
                                INSTITUTION NAME
                            </th>
                            <th class="text-left  blue darken-2 white--text">
                                INSTITUTION ADDRESS
                            </th>
                            <th class="text-left  blue darken-2 white--text">
                                VIDEO TITLE
                            </th>
                            <th class="text-left  blue darken-2 white--text">
                                VIDEO LINKS
                            </th>
                            <th class="text-left blue darken-2 white--text">
                                 <v-menu offset-y >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        color="blue darken-2 "
                                        text
                                        >
                                      <span class="text-left blue darken-2 white--text"><b>VIDEO STATUS</b> <v-icon left top>mdi-menu-down</v-icon> </span> 
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                        v-for="(item, index) in videoStatus"
                                        :key="index"
                                        >
                                        <v-list-item-title><v-btn class="text-left " text @click="filterStatus(item.title)">{{ item.title}}</v-btn>
                                        </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                    </v-menu>
                               
                            </th>
                            <th class="text-left  blue darken-2 white--text">
                                SUBMITTED DATE
                            </th>
                            <th class="text-left blue darken-2 white--text">
                                UPDATED DATE
                            </th>
                            <th v-if="ADMIN.role == 'SUPER ADMIN'" class="text-left blue darken-2 white--text">
                                VIDEO VERIFICATION
                            </th>
                            <th v-if="ADMIN.role == 'SUPER ADMIN'" class="text-left  blue darken-2 white--text">
                                ACTION
                            </th>
                            </tr>
                        </thead>
                        
                        <tbody >
                            <tr
                                v-for="(item,index) in users.result"
                                :key="index"
                                
                            >
                            <td class="text-left black--text">{{ index+1 + ((page-1) *offset) }}</td>
                            <td class="text-left black--text"><b>{{item.item.name}}</b></td>
                            <td class="text-left black--text" v-if="item.participant"> {{item.participant.name}}</td>
                            <td class="text-left black--text" v-else>NA</td>
                            <td class="text-left black--text" v-if="item.votes"><b>{{item.votes}}</b></td>
                            <td class="text-left black--text" v-else><b> 0</b></td>
                            <td class="text-left black--text"><b>{{item.participant.phone}}</b></td>
                            <td class="text-left black--text" v-if="item.participant">{{item.participant.address.district}}</td>
                            <td class="text-left black--text" v-else>NA</td>
                            <td class="text-left black--text" v-if="item.participant && item.participant.institution"> <b>{{item.participant.institution.division}}</b></td>
                            <td class="text-left black--text" v-else>NA</td>
                            <td class="text-left black--text" v-if="item.participant && item.participant.institution">{{item.participant.institution.institution_name}}</td>
                            <td class="text-left black--text" v-else>NA</td>
                            <td class="text-left black--text" v-if="item.participant && item.participant.institution">{{item.participant.institution.institution_address}}</td>
                            <td class="text-left black--text" v-else>NA</td>
                            <td class="text-left black--text">{{item.title}}</td>
                            <td class="text-left black--text"><v-btn small @click="showVideo(item.video_url)" dark class="blue darken-2" rounded>show video</v-btn></td>
                            <td class="text-left green--text" v-if="item.statusMsg == 'Submission Approved'" > <i><b>Approved</b> </i> </td>
                            <td class="text-left red--text" v-else-if="item.statusMsg == 'Submission rejected'" ><b>Rejected</b> </td>
                            <td class="text-left blue--text" v-else>Pending</td>
                            <td class="text-left black--text">{{item.created_on | moment("Do MMM YY, hh:mm a")}}</td>
                            <td class="text-left black--text">{{item.created_on | moment("Do MMM YY, hh:mm a")}}</td>
                            <td class="text-left black--text"  v-if="ADMIN.role == 'SUPER ADMIN'">
                                <v-btn @click="approveVideo(item._id)" class="green--text white my-1" rounded x-small> <b>Approve</b> </v-btn>
                                <v-btn @click="rejectVideo(item._id)" class="red--text my-1 white" rounded x-small> <b>Reject</b> </v-btn>
                            </td>
                            <td class="text-left black--text" v-if="ADMIN.role == 'SUPER ADMIN'" ><v-btn @click="deleteSubmission(item._id)" text ><v-icon color="red">mdi-delete</v-icon></v-btn> </td> 
                            
                        </tr>
                         </tbody>
                        </template>
                  </v-simple-table>
                    <v-dialog
                        v-model="dialog"
                        max-width="1030"
                       max-height = "200"
                        v-if="option.url"
                        >
                        <v-row dense align="center" justify="center">
                            <v-col cols="12">
                                <v-card width="1000" class="black">
                                    <v-btn v-if="option.url" @click="closeVideo()" text>
                                        <v-icon  class="mr-3 mt-1 mb-2 red white--text" size="20">mdi-close</v-icon>
                                        <v-badge overlap  color="red"  class="mb-2" tile @click="closeVideo()" content="close video"></v-badge>
                                    </v-btn>
                                <Artplayer v-if="option.url" :option="option" :style="style" class="ml-5 " />
                             </v-card>
                            </v-col>
                            <!-- <v-col cols="1">
                                <v-btn v-if="option.url" @click="closeVideo()" class=" red--text darken-4 white" style="margin-left:-8px;" height="500" text><b><v-icon> mdi-close</v-icon> <p>close</p></b></v-btn>
                            </v-col> -->
                        </v-row>
                        
                    </v-dialog>
              </v-flex>
            </v-layout> 
                    
        </v-flex>
    </v-layout>

</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'
import { mapGetters } from 'vuex'
import Artplayer from "./ArtPlayer"


export default {
    data(){
        return {
            sortVote:false,
            page:1,
            pageLength:1,
            offset :50,
            loading:false,
            dateSorting:true,
            sort:true,
            users : [],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
            dateOne: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menuOne: false,
            modalOne: false,
            menu2One: false,
            searchText:"",
            option: {
            url:"",
            fullscreen: true,
            theme:"green",
            autoplay: true,
            aspectRatio: true,
            divisionValue :"",
            itemValue:"",
            sortOffset:""
              },
            style: {
                width: "auto",
                height: "500px",
                // margin : " 0 0 0 20px"
            },
            dialog: false,
            division: [
                    { title: 'Lower Primary' },
                    { title: 'Upper Primary' },
                    { title: 'High School' },
                    { title: 'Higher Secondary' },
                    { title: 'University' }
                ],
            events: [],
            videoStatus: [
                    { title: 'pending' },
                    { title: 'approved' },
                    { title: 'rejected' },
                    
                ],
            approvalStatus:""
        }
    },
    async mounted(){
    
        {
            this.loading = true
            let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/submission/list?page="+ this.page + "&offset=" + this.offset,
            headers: {
                'Authorization': this.TOKEN
            }
         })
            
            this.users = data
            this.loading = false
        }
        {
            this.loading = true
            let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/submission/list",
            headers: {
                'Authorization': this.TOKEN
            }
            
         })
         this.sortOffset = data.records
         this.pageLength = parseInt(data.result.length /this.offset) + 1
         this.loading = false
        }
        {
         
            let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/item",
            headers: {
                'Authorization': this.TOKEN
            }
         })
            this.events = data
           

        }                
    },
    computed:{
        ...mapGetters(['ADMIN','TOKEN'])
    },
    components: {
    Artplayer,
   
  },
    methods : {
       gotoDetails(id){
        this.$router.push({name:'UserDetails',params:{id:id}})
       },
       async gotoPage(page){
   
           this.loading = true
           let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/submission/list?page="+ page + "&offset=" + this.offset,
            headers: {
                'Authorization': this.TOKEN
            }
         })
           this.users = data
           this.loading = false
        },
    async deleteUser(id){
       
    swal({
                  title: "Are you sure?",
                  text: "The Submissions will be removed permanantly!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then( async (willDelete) => {
                  if (willDelete) {
                    let {data}  = await Axios({
                        method : 'delete',
                        url : BASE_URL + "/submission/delete/" + id,
                        headers: {
                            'Authorization': this.TOKEN
                        }
                    })
                   await swal("Deleted", "Submissions removed successfully!", {
                      icon: "success",
                    });
                  
                    this.$router.go('')
                  } else {
                    swal("The Participant was not deleted!");
                  }
                })
                
      
    },
    async deleteSubmission(id){
      
    swal({
                  title: "Are you sure?",
                  text: "The Submissions will be removed permanantly!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then( async (willDelete) => {
                  if (willDelete) {
                    let {data}  = await Axios({
                        method : 'delete',
                        url : BASE_URL + "/submission/delete/" + id,
                        headers: {
                            'Authorization': this.TOKEN
                        }
                    })
                   await swal("Deleted", "Submissions removed successfully!", {
                      icon: "success",
                    });
                   
                    this.$router.go('')
                  } else {
                    swal("The Submission was not deleted!");
                  }
                })
                
      
    },
    async filterDivision(item){
            this.divisionValue = item
            if(this.itemValue != undefined){
                let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/submission/list?division=" + item +"&item=" + this.itemValue,
            headers: {
                'Authorization': this.TOKEN
            }
         })
         this.users = data
            }
            else{
                let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/submission/list?division=" + item ,
            headers: {
                'Authorization': this.TOKEN
            }
         })
         this.users = data
            }
        },

    async filterEvents(item){
            this.itemValue = item
            if(this.divisionValue != undefined){
                let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/submission/list?item=" + item + "&division=" + this.divisionValue ,
            headers: {
                'Authorization': this.TOKEN
            }
         })
         this.users = data
            }
            else{
                let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/submission/list?item=" + item ,
            headers: {
                'Authorization': this.TOKEN
            }
         })
         this.users = data
            }
        },

        async filterStatus(item){
            this.dateSorting = false
           if(item == "pending"){
            this.loading = true
            let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/submission/list?msg=" + "Approval pending" ,
            headers: {
                'Authorization': this.TOKEN
            }
         })
         this.users = data
         this.approvalStatus = "PENDING"
         this.loading = false
           }
           else if(item == "approved"){
            this.loading = true
            let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/submission/list?msg=" + "Submission Approved" ,
            headers: {
                'Authorization': this.TOKEN
            }
         })
         this.users = data
         this.approvalStatus = "APPROVED"
         this.loading = false
           }
           else if(item == "rejected"){
            this.loading = true
            let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/submission/list?msg=" + "Submission rejected" ,
            headers: {
                'Authorization': this.TOKEN
            }
         })
         this.users = data
         this.approvalStatus = "REJECTED"
         this.loading = false
           }
        },
        
       async sortVotes(){
        
            this.dateSorting = false
           if(this.sortVote == false){
            this.loading = true
            let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/submissions/list?sort_by=vote&offset=" + this.sortOffset,
            headers: {
                'Authorization': this.TOKEN
            }
        })
        this.users = data
        this.sortVote = true
        this.loading = false
     }
    else{
        this.loading = true
        let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/submissions/list?sort_by=voteDec&offset=" + this.sortOffset,

            headers: {
                'Authorization': this.TOKEN
            }
        })
        this.users =data
        this.sortVote = false
        this.loading = false
     }
           
    },

     search(){
        if(this.searchText){
                this.$router.push({name:'searchSubmissions',params:{search:this.searchText}})
                  }
                  else{
                    return this.users.result;
                   
                  }
                },
     showVideo(link){
        this.option.url = ""
        this.option.url = link
        this.dialog = true
     },
     closeVideo(){
     this.option.url = ""
    //  this.$router.go('')
  },    
  async approveVideo(id){
    
    let {data}  = await Axios({
       
            method : 'post',
            url : BASE_URL +  "/submissions/update/" + id,
            headers: {
                'Authorization': this.TOKEN
            }
         })
         this.$router.go('')
  },
  async rejectVideo(id){
  
    let {data}  = await Axios({
            method : 'post',
            url : BASE_URL + "/submissions/rejected/" + id,
            headers: {
                'Authorization': this.TOKEN
            }
         })
         this.$router.go('')
  },
 async filterSubmission(){
    this.loading = true
    let  url = BASE_URL + "/submissions/filter"
    if(this.date <= this.dateOne){
        let payload = {previous : this.date,next : this.dateOne}
        let {data}  = await Axios.post(url,payload)
        this.users = data
        this.dateSorting = false
        this.sort = false
        this.loading = false
        this.page = 1
    }
    else{
        this.$toasted.error("From date must be less than To date")
    }
  }

 }
}
</script>

<style scoped>
 
</style>


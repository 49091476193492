<template>
    <v-layout row wrap class=" mx-5">
        <v-flex xs12 md12 ma-5>
            <div class="white mt-6 ">
                    <h2 class="teal--text text-center">
                    BANNERS
                    </h2>
              </div>
              </v-flex>
        <v-flex xs12 class="teal pa-3 mb-5">
          <p class="ma-0 caption white--text" style="text-transform:uppercase"><b>BANNERS</b> </p>
        </v-flex>
        <v-flex xs12 md8 class="pl-10 pr-10 pt-3">
            <v-layout row wrap class="white pa-5">
                <v-flex xs12>
                    <p class="caption"><b>VIEW BANNERS</b></p>
                    <v-divider></v-divider>
                </v-flex>
                <v-flex v-for="item in banners" :key="item.url" xs12 md4 class="white pl-5 pb-5 pr-5">
                    <img :src="item.url" width="100%"/>
                     <div class="text-right">
                        <v-btn v-if="ROLE == 'SUPER ADMIN'" class="white pink--text" small @click="deleteBanner(item._id)">
                            <v-icon>close</v-icon>
                        </v-btn>
                     </div> 
                </v-flex>
                <v-flex v-if='banners.length==0' class="pt-10">
                         <v-alert border="top" color="purple lighten-1" dark >No banners found</v-alert>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex xs12 md4>
            <uploader @uploadSuccess="uploadSuccess"></uploader>
            <div class="pa-5 white" v-if="image_url!=''"><img :src="image_url.url" width="100%"/></div>
            <div class="mt-2"><v-btn class="teal white--text" block large @click="saveBanner">Save Banner</v-btn></div>
        </v-flex>
    </v-layout>
</template>

<script>
import Axios from 'axios'
import Uploader from '../components/Uploader.vue'
import { BASE_URL } from '../config'
import { mapGetters } from 'vuex'

export default {
    data(){
        return{
            image_url : "",
            banners : []
        }
    },
    components : {
        Uploader
    },
    computed:{
        ...mapGetters(['ADMIN','ROLE','TOKEN'])
    },
    methods : {
        uploadSuccess(value){
            this.image_url = value.url
        },
        async saveBanner(){
            // console.log(this.image_url.url);
            {
            let payload = { url : this.image_url}
            let {data}  = await Axios({
            method : 'post',
            url : BASE_URL + '/banner/',
            data : payload,
            headers: {
                'Authorization': this.TOKEN
            }
         })    
        }    
            {
                let url = BASE_URL + "/banner"
                let {data} = await Axios.get(url)
                this.banners = data
            }
            
            
        },
        async deleteBanner(id){
            {
              await Axios.delete(BASE_URL + "/banner/"+id,{headers: {'Authorization': this.TOKEN}}) 
              this.$router.go('')
            }

            {
                let url = BASE_URL + "/banner"
                let {data} = await Axios.get(url)
                this.banners = data
            }
            
        }
        
    },
    async mounted(){

            let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + '/banner',
            headers: {
                'Authorization': this.TOKEN
            }
         })
         this.banners = data 
    }
}
</script>
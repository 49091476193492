  <template>
    <div>
       <v-layout rowwrap >
      <v-flex xs12 md2 mt-12 v-for="(item,index) in  items" :key="index">
        <v-hover>
      <template v-slot:default="{ hover }">
        <v-card
        :elevation="hover ? 24  : 6"
        class="mx-auto"
        max-width="180"
        link
       style="border-radius:20px;border-left:3px solid grey;border-bottom:4px solid grey;"
      >
      <v-card-text>
          <v-row justify="center">
            <v-col cols="6">
              <v-avatar size="70" :color=item.back>
              <v-icon large :color=item.color>{{item.icon}}</v-icon>
              </v-avatar>
            </v-col>
          </v-row>
        </v-card-text>
        <v-list-item two-line>
          <v-list-item-content class="text-center">
            <v-list-item-title class="">
              {{item.title}} 
              <!-- {{pickers}} -->
            </v-list-item-title>
            <v-list-item-subtitle class="grey--text caption">
                {{item.subTitle}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        
        <v-list-item-content class="text-center">
        <v-list-item-title :class="item.textColor">
          <h2>{{item.count}}</h2>
        </v-list-item-title>
        </v-list-item-content>
      </v-card>
      </template>
      </v-hover>
          </v-flex>
          <v-flex xs12 md2 class="mt-12">
            <v-card rounded="xl">
              <v-date-picker v-model="picker" color="green--text" dark full-width></v-date-picker>
            </v-card>
          </v-flex>
    </v-layout>
    <v-flex xs12 md4>
      <template>
  <v-card
    class="mt-4 mx-10"
    max-width="1200"
    color="grey lighten-2"
  >
    <v-sheet
      class="v-sheet--offset mx-auto"
      color="grey darken-2"
      elevation="12"
      max-width="calc(100% - 32px)"
    >
      <v-sparkline
        :labels="labels"
        :value="value"
        color="white"
        line-width="2"
        padding="16"
        
      ></v-sparkline>
    </v-sheet>

    <v-card-text class="pt-0">
      <div class="text-h6 font-weight-light mb-2">
       <b> User Registrations </b>
      </div>
      <!-- <div class="subheading font-weight-light grey--text">
          Last Campaign Performance
      </div> -->
      <v-divider class="my-2"></v-divider>
      <v-icon
        class="mr-2"
        small
      >
        mdi-clock
      </v-icon>
      <span class="text-caption font-weight-light">last registration 26 minutes ago</span>
    </v-card-text>
  </v-card>
  
</template>
    </v-flex>
    
  </div>
 </template>

<script>
export default {
data(){
  return{
    items:[
      {title:"Total Registered",subTitle:"60% users",image:"https://cdn.vuetifyjs.com/images/cards/cooking.png",count:12000,color:"white",back:"green",textColor:"green--text",icon:"mdi-account-multiple"},
      {title:"Daily users",subTitle:"20% users",image:"https://cdn.vuetifyjs.com/images/cards/sun.png",count:390,color:"white",back:"orange",textColor:"orange--text",icon:"mdi-account-box-multiple"},
      {title:"Total Schools",subTitle:"12%schools",image:"https://cdn.vuetifyjs.com/images/cards/sun.png",count:5,color:"white",back:"purple",textColor:"purple--text",icon:"mdi-school"},
      {title:"Events",subTitle:"5 new events",image:"https://cdn.vuetifyjs.com/images/cards/sun.png",count:2,color:"white",back:"blue",textColor:"blue--text",icon:"mdi-palette"},
      {title:"District",subTitle:"Alleppey",image:"https://cdn.vuetifyjs.com/images/cards/sun.png",count:30,color:"white",back:"pink",textColor:"pink--text",icon:"mdi-office-building-marker"},
    ],
    picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    labels: ['oct 22','oct 23','oct 24','oct 25', new Date().toLocaleDateString('en-us', {month:"short", day:"numeric"}),
    new Date().toLocaleDateString('en-us', {month:"short", day:"numeric"})],
    value: [200,675,410, 390,310,460,250,240,],
      
  }
},

computed:{
  dates(){
    return new Date().toLocaleDateString('en-us', {month:"short", day:"numeric"})
  }
}
}
</script>

<style scoped>

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }

 
</style>

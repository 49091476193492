<template>
    <v-layout row wrap style="height:100%;margin:0 -16px;">
      <v-img
        :src="image"
        relative
        width="100%"
        >
        <v-card
    class="mx-auto mt-10 pa-3"
    max-width="1000"
    elevation="6"
    >
    <v-layout row wrap>
      <v-flex xs12 md6>
        <v-hover v-slot="{ hover }">
        <v-card class="image"  :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }">
        <v-img
        :src="images"
        relative
        aspect-ratio="1"
        >
        <!-- <v-btn absolute bottom left xs12 md12 @click="login"  rounded small color="#80CBC4" width="200" > <b> Signup</b></v-btn> -->
        </v-img>
        
    </v-card>
  </v-hover>
      </v-flex>
      <v-flex xs12 md6>
        <v-card  elevation="0">
      <v-card-title class="justify-center" >
     <h3 class="green--text mt-10 ">
        CHANGE PASSWORD
     </h3> 
    </v-card-title>
        <v-container fluid style="max-width:400px"> 
          <v-row dense >
                  <v-col  cols="12" md="12">
                    <v-text-field color="green" single-line outlined v-model="currentPassword" type="password" label="Current Password" clearable  > 
                  </v-text-field>
                 </v-col>
                  <v-col  cols="12" md="12">
                    <v-text-field color="green" single-line outlined v-model="newPassword" type="password" label="New Password" clearable > 
                  </v-text-field>
                 </v-col>
                 <v-col  cols="12" md="12">
                    <v-text-field color="green"  single-line outlined v-model="confirmPassword" type="password" label="Confirm Password" clearable > 
                    </v-text-field>
                 </v-col>
                <v-col cols="12" md="12" align="center">
                 <v-btn xs12 @click="changePassword" rounded large class="green white--text ">Change Password</v-btn>   
                </v-col>
                </v-row>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>   
  </v-img>
 </v-layout>
</template>
  
  <script>
  import Axios from 'axios'
  import { BASE_URL } from '../config'
  import { mapGetters } from 'vuex'
  export default {
      data(){
           return{
               currentPassword:"",
               newPassword:"",
               confirmPassword:"",
               images:require('../assets/Signup1.jpg'),        
           }
       },
       methods : {
        async changePassword(){
          if(this.currentPassword != '' && this.confirmPassword != '' && this.newPassword != '' ){

            let payload = {
                            email:this.CHANGEPASSWORD,
                            password: this.currentPassword,
                            newPassword:this.newPassword
            }
                if(this.newPassword == this.confirmPassword){
                    // let url =  BASE_URL + "auth/admin-district/change-password"
                    let {data} = await Axios.post(url,payload)
                                  .then((response) => {
                                    if(response.success = true){
                                      this.$toasted.success(response.data.msg)
                                      this.$router.push('/') 
                                    }
                                    else{
                                      this.$toasted.error(response.data.msg)
                                      
                                    }
                                  })
                      
                }
                 else{
                  this.$toasted.error('Password doesnot match')
                 }         
                
          }
          else{
            this.$toasted.error('Please fill all columns')
          }
            
                }
           
       },
       computed:{
        ...mapGetters(['CHANGEPASSWORD'])
       }
  }
  </script>
  
 
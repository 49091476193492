<template >
  <v-layout class="design" style="height:auto;min-height:1050px">
    <v-container class="ma-auto" md12 sm12>
      <v-card class="text-center cardOne pa-3"  elevation="24" max-width="400" style="border-radius:10px;margin-left: 40px;">
        <v-card class="white" style="border-radius:10px;" >
          <h2 class="purple--text">PARTICIPANT DETAILS</h2>
        </v-card>
      
      </v-card>
      <v-card class="cardOne lighten-2 ma-12 pa-7" width="auto" height="auto" elevation="24" style="border-radius:15px">
    <v-layout row wrap>
      
      <v-flex xs12 md3 style="margin:10px;padding-right:15px;">
        <v-card class="ma-auto pa-auto" width="auto" min-height="400" style="border-radius:10px">
          <v-img
          :src = profile
        
        >
        </v-img> 
   <v-simple-table class="ma-auto" >
    <template v-slot:default>
      <thead> <tr></tr></thead>
      <tbody>
        <tr>
          <td><b>Name </b>  </td>
          <td class="purple--text">{{userDetails.name}}</td>
        </tr>
        <tr>
          <td><b>Division</b> </td>
          <td class="purple--text" v-if="userDetails.institution">{{userDetails.institution.division}}</td>
        </tr>
        <!-- <tr>
          <td> <b>Rank</b> </td>
          <td class="purple--text"><h3>1</h3></td>
        </tr> -->
        <tr>
          <td> <b>Votes </b> </td>
          <td class="purple--text"> <h4 v-for="item in submission" :key="item._id" class="mt-2"> {{item.item.name}} : 
           <span class="black--text">{{item.votes}}</span> </h4></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
<v-card-subtitle>
      
    </v-card-subtitle>
        </v-card>
      </v-flex>
       
  <v-flex  md5 sm12 style="margin:10px;padding-right:15px;">
    <v-card class="ma-auto pa-auto" style="border-radius:10px" min-height="470" max-width="600" width="auto">
      <v-simple-table class="ma-auto pa-auto" >
    <template v-slot:default >
      <thead> <tr></tr></thead>
      <tbody>
        <tr>
          <td style="padding:20px" ><b>Address </b> </td>
          <td class="purple--text">{{userDetails.address.line1}}, {{userDetails.address.line2}}, {{userDetails.address.district}}, {{userDetails.address.state}}-{{userDetails.address.country}}</td>
        </tr>
        <tr>
          <td style="padding:20px"><b>Phone</b> </td>
          <td class="purple--text">{{userDetails.phone}}</td>
        </tr>
        <tr>
          <td style="padding:20px"> <b>Email </b> </td>
          <td class="purple--text">{{userDetails.email}}</td>
        </tr>
        <tr>
          <td style="padding:20px"> <b>Registration Date </b> </td>
          <td class="purple--text">{{userDetails.created_on | moment("MMMM Do YYYY, h:mm:ss a")}}</td>
        </tr>
        <tr>
          <td style="padding:20px"> <b>Last Updated Date </b> </td>
          <td class="purple--text">{{userDetails.last_updated | moment("MMMM Do YYYY, h:mm:ss a")}}</td>
        </tr>
        <tr>
          <td style="padding:20px"> <b>Institution Details </b> </td>
          <td class="purple--text" v-if="userDetails.institution">{{userDetails.institution.division}},{{userDetails.institution.institution_name}},{{userDetails.institution.institution_address}}</td>
        </tr>
      
      </tbody>
    </template>
  </v-simple-table>
    </v-card>
      <!-- <v-dialog
      v-model="dialog"
     max-width="900"
     v-if="option.url"
    >
    <v-btn v-if="option.url" @click="closeVideo()" text small class="mt-1 red--text darken-4"><b><v-icon> mdi-close </v-icon></b></v-btn> 
    <Artplayer v-if="option.url"  class="" :option="option" :style="style" />
      
   </v-dialog> -->
   <v-dialog
                        v-model="dialog"
                        max-width="1030"
                       max-height = "200"
                        v-if="option.url"
                        >
                        <v-row dense align="center" justify="center">
                            <v-col cols="12">
                                <v-card width="1000" class="black">
                                    <v-btn v-if="option.url" @click="closeVideo()" text>
                                        <v-icon  class="mr-3 mt-1 mb-2 red white--text" size="20">mdi-close</v-icon>
                                        <v-badge overlap  color="red"  class="mb-2" tile @click="closeVideo()" content="close video"></v-badge>
                                    </v-btn>
                                <Artplayer v-if="option.url" :option="option" :style="style" class="ml-5 " />
                             </v-card>
                            </v-col>
                        </v-row>
                    </v-dialog>
    </v-flex>
    <v-flex sm12 md3 style="margin:10px;padding-right:15px;">
     
      <v-card class="mx-auto " width="auto" height="470" style="border-radius:10px;overflow: auto;">
      <v-card-title class="justify-center purple--text">
       <b>EVENTS</b> 
      </v-card-title>
      <v-divider class="mx-5"></v-divider>
      <ul style=" list-style-type:none">
        <li v-for="item in userDetails.items" :key="item._id" class="ma-5">
          <v-btn class="mr-2 playButton" dark rounded small @click="playVideo(item._id)">
          <v-icon>mdi-motion-play-outline</v-icon>play</v-btn><span><b>{{item.name}}</b></span> </li> 
      </ul>
            </v-card>
          
         </v-flex>
        </v-layout>
      </v-card>
     </v-container>
   </v-layout> 
</template>

<script>
import { BASE_URL } from '../config'
import Artplayer from "./ArtPlayer"
import { mapGetters } from 'vuex'
import Axios from 'axios'

export default {
  
data() {
    return {
      rating:"",
      profile:require('../assets/logo1.png'),
      userDetails:[],
      submission:[],
      play:false,
      showbutton:true,
      image:require('../assets/user.jpg'),
      option: {
        url:"",
        fullscreen: true,
        theme:"green",
        autoplay: true,
        aspectRatio: true
      },
      style: {
        width: "auto",
        height: "500px",
      },
      blockUser:true,
      dialog: false,
    }
  },
  async mounted(){
           {    
            let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + '/registration/detail/' + this.$route.params.id,
            headers: {
                'Authorization': this.TOKEN
            }
         })
          this.userDetails = data  
          if(data.display_picture){
            this.profile = data.display_picture
          }
          
        }     
        {    
            let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + '/submission/list/' + this.$route.params.id,
            headers: {
                'Authorization': this.TOKEN
            }
          })  
          this.submission = data
          // this.option.url = this.submission[0].video_url
        }
  },
  computed:{
    ...mapGetters(['ADMIN','TOKEN'])
  },
  components: {
    Artplayer,
   
  },
  methods: {
    playVideo(id){
      this.option.url = ""
      this.dialog = true
        for(let i=0; i<this.submission.length; i++){
          if(this.option.url == ""){
            if(this.submission[i].item._id == id){
               return this.option.url = this.submission[i].video_url
          }
       }  
     }
  },
  closeVideo(){
     this.option.url = ""
     this.$router.go('')
  },
    video(){
      this.play = true
      this.showbutton = false
    },
    hidevideo(){
      this.showbutton = true
      this.play = false
    },
    // getItems(loc){
    //   let _locs= "";
      
    //     for(var i=0;i<loc.length;i++){
    //       _locs += loc[i].name
    //       if(loc.length-1!=i){
    //           _locs += ", "
    //       }
    //   }
    //   return _locs
      
      
    // },
   async blocked(){
           await swal({
                  title: "Are you sure?",
                  text: "The Participant will be blocked!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then((willDelete) => {
                  if (willDelete) {
                    let {data}  = Axios({
                            method : 'patch',
                            url : BASE_URL + '/registration/block/' + this.$route.params.id,
                            // headers: {
                            //     'Authorization': this.TOKEN
                            //   }
                        })
                    swal("BLOCKED", "Participant blocked successfully!", {
                      icon: "success",
                    });
                    this.blockUser = false
                    // this.$router.go('')
                  } else {
                    swal("The participant was not blocked!");
                  }
                })
    },
     async unBlock(){
      let {data}  = Axios({
                            method : 'patch',
                            url : BASE_URL + '/registration/unblock/' + this.$route.params.id,
                            // headers: {
                            //     'Authorization': this.TOKEN
                            //   }
                        })
        this.blockUser= true
    },
   
  },

}
</script>

<style scoped>

  .cardOne {
    background-color: rgba(255, 255, 255, 0.1)
  }
  
    .design, .playButton {
      background-image: linear-gradient(to bottom right, #5a235c 0%,  #1da3d8 100%);
    }
    .playButton:hover {
      background-image: linear-gradient(to top left, #5a235c 0%,  #1da3d8 100%);
    }
    div.relative {
        position: relative;
         top: 10px;
        left: 70px;
        width: 150px;
        height: 150px;
        border: 1px solid #73AD21;
        border-radius: 20px;
        /* transform: rotate(65deg) */
      } 
      
      div.absolute {
        position: absolute;
        width: 300px;
        height: 700px;
        border: 1px solid #73AD21;
        margin-top: 50px;
        border-radius: 30px;
        background-image:url(../assets/newuser.jpg); 
        background-size: 300px 700px;
      }
      .textone{
        margin-top: 110px;
        text-align: center;
      }
      .details:hover{
        transform: scale(1.02,1.2)
      }
</style>

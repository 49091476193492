<template>
  <v-app class="red" >
    <app-tool-bar v-if=true > 
    </app-tool-bar>
   <v-main v-if=LOGIN class="custom-style" style="height:100%">
        <div>
            <router-view ></router-view>
        </div>
    </v-main>
    <v-main v-else>
      <login></login>
    </v-main>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex'
import ToolBar from './components/ToolBar'
import Login  from './components/Login'

export default {
  name: 'App',

  components: {
    AppToolBar : ToolBar,
    Login
    
  },
  computed:{
    ...mapGetters(['LOGIN'])
  },
 
  data: () => ({
    //
  }),
};
</script>

<style>
.custom-style{
    /* font-family: "Perpetua"; */
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
</style>


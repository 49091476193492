<template>
    <v-layout row wrap >
        <v-flex xs12 md12 class="ml-2">
            <div class="white mt-6 ">
                    <h1 class="green--text text--darken-4 text-center">
                        <b >KALOLSAVAM REGISTERED USERS</b> 
                       <p v-if="!dateSorting" style="font-size:22px" class="black--text" ><span class="mx-3">{{date | moment("Do MMM YY")}}</span> - <span class="mx-3">{{dateOne | moment("Do MMM YY")}}</span></p> 
                    </h1>
              </div>
                <v-container row wrap mt-2>
                    <v-flex xs6 md3 ml-8>
                        <v-text-field
                        label="search "
                        placeholder="search"
                        outlined
                        color="green"
                        append-icon="search"
                        dense
                        v-model="searchText"
                ></v-text-field>
                 </v-flex>
                 <v-flex md1 ml-5 xs4>
                    <v-btn block height="40" class="green--text text--darken-4" dark @click="search()" outlined elevation="2" > <b >search</b></v-btn>
                 </v-flex>
                 <v-spacer></v-spacer>
                 
                 <v-flex md3 xs12 class="ml-12">
                    <v-row>
                        <v-col cols="4" sm="4" class="mx-2" 
                        >
                        <v-menu ref="menu"  v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto" 
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="date" label="From Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="green" type="date">
                            </v-text-field>
                            </template>
                            <v-date-picker v-model="date" no-title scrollable color="green">
                            <v-spacer></v-spacer>
                            <v-btn text color="green" @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn text color="green" @click="$refs.menu.save(date)" >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                        </v-col>
                       
                        <v-col cols="4" sm="4" class="mx-2"
                        >
                        <v-menu ref="menuOne"  v-model="menuOne" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateOne" label="To Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="green" type="date">
                            </v-text-field>
                            </template>
                            <v-date-picker v-model="dateOne" no-title scrollable color="green" >
                            <v-spacer></v-spacer>
                            <v-btn text color="green" @click="menuOne = false">
                                Cancel
                            </v-btn>
                            <v-btn text color="green" @click="$refs.menuOne.save(date)" >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                        </v-col>
                        <v-cols cols="4" sm="4" class="mt-6 mx-2">
                            <v-btn class="green--text text--darken-4" elevation="2" @click="filterParticipant" outlined> <b>filter</b> </v-btn>
                        </v-cols>
                        </v-row>
                       
                 </v-flex>
                 
                </v-container>  
                <div class="text-center" >
                    <v-progress-circular
                    v-if="loading"
                    :size="50"
                    color="green"
                    indeterminate
                    ></v-progress-circular>
                </div>                 
                <div class="ml-8 my-3" xs12 md12>
               <h3 class="green--text text--darken-4"> TOTAL REGISTERED PARTICIPANTS : <span class="black--text">{{users.count}}</span></h3> 
              </div>
              <v-pagination 
               v-if="dateSorting"
               circle
                v-model="page"
                :length="pageLength"
                :total-visible="7"
                @input="gotoPage(page)"
                color="green darken-4"
                class="ma-2"
                ></v-pagination>  
              <v-layout class="ml-3 mr-3">
                <v-flex xs12 md12>
                <v-simple-table class="mx-5" dense>
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left green darken-4 white--text">
                                #
                            </th>
                            <th class="text-left  green darken-4 white--text">
                                NAME
                            </th>
                            <!-- <th class="text-left  green darken-2 white--text">
                                EMAIL
                            </th> -->
                            <th class="text-left  green darken-4 white--text">
                                PHONE
                            </th>
                            <th class="text-left  green darken-4 white--text">
                                DISTRICT
                            </th>
                            <th class="text-left  green darken-4 white--text">
                                DIVISION
                            </th>
                            <th class="text-left  green darken-4 white--text">
                                INSTITUTION NAME
                            </th>
                            <!-- <th class="text-left  green darken-2 white--text">
                                INSTITUTION ADDRESS
                            </th> -->
                            <th class="text-left  green darken-4 white--text">
                                ITEMS
                            </th>
                            <th class="text-left  green darken-4 white--text">
                                REGISTERED DATE
                            </th>
                            <th class="text-left green darken-4 white--text">
                                UPDATED DATE
                            </th>
                            <th class="text-left  green darken-4 white--text">
                                DETAILS
                            </th>
                            <th v-if="ADMIN.role == 'SUPER ADMIN'" class="text-left  green darken-4 white--text">
                                ACTION
                            </th>
                            </tr>
                        </thead>
                        
                        <tbody >
                            <tr
                                v-for="(item,index) in users.result"
                                :key="index"
                            >
                            <td class="text-left black--text">{{ index+1 + ((page-1) *offset) }}</td>
                            <td class="text-left black--text"><b>{{item.name.toUpperCase()}}</b></td>
                            <!-- <td class="text-left black--text">{{item.email}}</td> -->
                            <td class="text-left black--text">{{item.phone}}</td>
                            <td class="text-left black--text" v-if="item.address">{{item.address.district}}</td>
                            <td class="text-left black--text" v-else>NA</td>
                            <td class="text-left black--text" v-if="item.institution">{{item.institution.division}}</td>
                            <td class="text-left black--text" v-else>NA</td>
                            <td class="text-left black--text" v-if="item.institution">{{item.institution.institution_name}}</td>
                            <td class="text-left black--text" v-else>NA</td>
                            <!-- <td class="text-left black--text" v-if="item.institution">{{item.institution.institution_address}}</td>
                            <td class="text-left black--text" v-else>NA</td> -->
                            <td class="text-left black--text" v-if="item.items"><p v-for="(items,index) in item.items" :key="index">{{items.name}}</p></td> 
                        
                            <td class="text-left black--text" >{{item.created_on  | moment(" Do MMM YYYY, hh:mm a")}}</td>
                            <td class="text-left black--text" >{{item.created_on  | moment(" Do MMM YYYY, hh:mm a")}}</td>
                            <td class="text-left" >
                                <div class="green--text darken-4 text-left" text @click="gotoDetails(item._id)"> <a class="green--text"><b>View Details</b><v-icon color="green" small>mdi-account-arrow-right</v-icon> </a></div>
                            </td>
                            <td class="text-left black--text" v-if="ADMIN.role == 'SUPER ADMIN'"><v-btn @click="deleteUser(item._id)" text   ><v-icon color="red">mdi-delete</v-icon></v-btn> </td> 
                            </tr>
                         </tbody>
                        </template>
                  </v-simple-table>
              </v-flex>
            </v-layout> 
                    
        </v-flex>
    </v-layout>
</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'
import { mapGetters } from 'vuex'


export default {
    data(){
        return {
            page:1,
            pageLength:1,
            offset :50,
            loading:false,
            dateSorting:true,
            users : [],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
            dateOne: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menuOne: false,
            modalOne: false,
            menu2One: false,
            searchText:""
    
        }
    },
    async mounted(){
    
        {
            this.loading = true
            let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/registration/list?page="+ this.page + "&offset=" + this.offset,
            headers: {
                'Authorization': this.TOKEN
            }
         })
            this.users = data
            this.pageLength = parseInt(data.count /this.offset) + 1
            this.loading = false
        }        
    },
    computed:{
        ...mapGetters(['ADMIN','TOKEN'])
    },
    methods : {
       gotoDetails(id){
        this.$router.push({name:'UserDetails',params:{id:id}})
       },
   async deleteUser(id){
    swal({
                  title: "Are you sure?",
                  text: "The Participant and Submissions will be removed permanantly!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then( async (willDelete) => {
                  if (willDelete) {
                    let {data}  = await Axios({
                        method : 'delete',
                        url : BASE_URL + "/registration/delete/" + id,
                        headers: {
                            'Authorization': this.TOKEN
                        }
                    })
                   await swal("Deleted", "Participant removed successfully!", {
                      icon: "success",
                    });
                    this.$router.go('')
                  } else {
                    swal("The Participant was not deleted!");
                  }
                });
      
    },
    search(){
     if(this.searchText){
                this.$router.push({name:'searchRegistrations',params:{search:this.searchText}})}
                  else{
                    return this.users.result;
                   
                  }
                },
     showVideo(link){
        this.option.url = ""
        this.option.url = link
        this.dialog = true
     },

async filterParticipant(){
    this.loading = true
    let  url = BASE_URL + "/participants/filter"
    if(this.date <= this.dateOne){
        let payload = {previous : this.date,next : this.dateOne}
        let {data}  = await Axios.post(url,payload)
        this.users = data
        this.dateSorting = false
        this.loading = false
        this.page = 1
    }
    else{
        this.$toasted.error("From date must be less than To date")
    }
  },

  async gotoPage(page){
    this.loading = true
    let {data}  = await Axios({
        method : 'get',
        url : BASE_URL + "/registration/list?page="+ page + "&offset=" + this.offset,
        headers: {
            'Authorization': this.TOKEN
    }
 })
   this.users = data
   this.loading = false
},

 }
}
</script>

<style scoped>
   
  thead{
    background-image: linear-gradient(266deg, #39810f 0%, #051f2b 100%);

    }
    .button{
        background-image: linear-gradient(266deg, #313031 0%, #076b07 100%);
    }
    .table {
  background-color: red;}
 
</style>>


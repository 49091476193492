<template>
    <v-layout row wrap>
        <v-flex xs12 md12 ma-5>
            <div class="white mt-6 ">
                    <h2 class="teal--text text-center">
                    CARDS{{ TOKEN }}
                    </h2>
              </div>
              </v-flex>
              <v-flex xs12 md8 class="pl-10 pr-10 pt-3">
            <v-layout row wrap class="white pa-5">
                <v-flex xs12>
                    <p class="caption"><b>VIEW CARDS</b></p>
                    <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 md3  v-for="item in cards" :key="item.url"  class="white pl-5 pb-5 pr-5 ma-5">
                    <img :src="item.thumbnail" width="100%"/>
                    <div> <b>{{item.title}}</b></div><br>
                    <div class="caption">{{item.text}}</div>
                     <div class="text-left mt-5">
                        <v-btn class="white pink--text" small @click="deleteBanner(item._id)">
                            Delete Card<v-icon>close</v-icon>
                        </v-btn>
                     </div> 
                </v-flex>
                <v-flex v-if='cards.length==0' class="pt-10">
                         <v-alert border="top" color="purple lighten-1" dark >No cards found</v-alert>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex xs12 md8 class="mt-10">
            <v-layout row wrap class="mb-10 white pa-10">
                <v-flex xs12 md12 class="pl-2 pr-2">
                    <p class="caption"><b>TITLE</b></p>
                    <v-text-field v-model="name" single-line outlined label="Name"></v-text-field>
                </v-flex>
                <v-flex xs12 md12 class="pl-2 pr-2">
                    <p class="caption"><b></b></p>
                    <v-textarea v-model="description" single-line outlined label="Description"></v-textarea>
                </v-flex>
                <v-flex xs12 md12 class="pl-2 pr-2">
                    <v-btn class="teal white--text pl-10 pr-10" large @click="saveDetails">Save</v-btn>
                </v-flex>
            </v-layout>
        </v-flex>
        
        <v-flex xs12 md4 class="pa-10">
            <p class="white caption pa-2"><b>BANNERS</b></p>
            <uploader @uploadSuccess="uploadSuccess"></uploader>
            <div class="pa-5 white" v-if="image_url!=''">
                <img :src="image_url.url" width="100%"/>
                </div>
            <div class="mt-2"><v-btn class="teal white--text" block large @click="addBanner">Save Banner</v-btn></div>
            <div class="pa-3">
                <v-layout row wrap class="mt-10 white pa-5">
                <v-flex xs6 v-for="item in banners" :key="item" class="pa-2">
                    <img :src="item" style="width:100%"/>
                    <div class="text-right">
                        <v-icon  class="pink--text" @click="deleteBanner(item)">delete</v-icon>
                    </div>
                </v-flex>
            </v-layout>
            </div>
        </v-flex>
       
    </v-layout>
</template>


<script>
import Axios from 'axios'
import Uploader from '../components/Uploader'
import { BASE_URL } from '../config'
import { mapGetters } from 'vuex'

export default {
    data(){
        return{
            name : "",
            description : "",
            image_url : '',
            banners : [],
            cards :[]
        }
    },
    async mounted(){

        let {data} =  await Axios.get(BASE_URL + "/cards",{headers: {'Authorization': this.TOKEN}})
        this.cards = data 
        console.log('data');
},
    computed:{
        ...mapGetters(['ADMIN','TOKEN'])
    },
    components : {
        Uploader
    },
    methods : {
        uploadSuccess(value){
            this.image_url = value
        },
        async saveDetails(){
            let payload = {
                title : this.name,
                text : this.description,
                thumbnail : this.image_url.url
            }

            await Axios.post(BASE_URL + "/card/",payload,{headers: {'Authorization': this.TOKEN}}) 
            
        },
        async addBanner(){
            {
                let payload = {
                    id : this.$route.params.id,
                    banner : this.image_url
                }
                let url = BASE_URL + "/card"
                let {data} = await Axios.post(url,payload)
                this.image_url = ""
            }
            
            {
                let url = BASE_URL + "/card"
                let {data} = await Axios.get(url)
                this.banners = data.banners
            }
            
        },
        async deleteBanner(banner){
            {
                let payload = {
                    id : this.$route.params.id,
                    banner : banner
                }
                let url = BASE_URL + "/course-details/remove-banner"
                let {data} = await Axios.post(url,payload)
            }
            {
                let url = BASE_URL + "/course-details/details?id="+this.$route.params.id
                let {data} = await Axios.get(url)
                this.banners = data.banners
            }
            

        },
        async removeBanner(banner){
            let payload = {
                id : this.$route.params.id,
                banner : banner
            }
            let url = BASE_URL + ""
            let {data} = await Axios.post(url,payload)
        },
       


    },

}
</script>
<template>
    <v-layout row wrap >
        <v-flex xs12 md12 class="ml-2">
            <div class="white mt-6 ">
                    <h1 class="text-center">
                        <b><v-icon class="mx-2" color="#CD7F32" large>mdi-trophy-award</v-icon>LEADER BOARD <v-icon class="mx-2" color="#CD7F32" large>mdi-trophy-award</v-icon></b> 
                        
                    </h1>
                    <h3 class="green--text text-center">{{$route.params.division.toUpperCase()}}</h3>
              </div>
                <v-container row wrap mt-2>
                    <v-flex xs6 md3 ml-12>
                    <v-text-field
                        label="Search Name"
                        placeholder="Search Name"
                        outlined
                        color="green"
                        append-icon="search"
                        dense
                        v-model="searchText"
                ></v-text-field>
                 </v-flex>
                 <!-- <v-flex md1 ml-5 xs4>
                    <v-btn block height="40" class="green button" dark @click="search">search</v-btn>
                 </v-flex> -->
                 <v-spacer></v-spacer>
                </v-container>                  
              <v-layout row-wrap class="mx-10 ">
                <v-flex xs12 md12>
                <v-simple-table class="ma-2" fixed-header dense >
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left green darken-4 orange--text" v-if ="searchText == ''">
                               <b>RANK #</b> 
                            </th>
                            <th class="text-left green darken-4 white--text" v-if ="searchText != ''">
                               <b> #</b> 
                            </th>
                            <th class="text-left green darken-4 white--text">
                                VOTES
                            </th>
                            <th class="text-left  green darken-4 white--text">
                                ITEMS
                            </th>
                            <th class="text-left  green darken-4 white--text">
                                PARTICIPANTS
                            </th>
                            <th class="text-left  green darken-4 white--text">
                                DISTRICT
                            </th>
                           
                            <th class="text-left  green darken-4 white--text">
                                INSTITUTION NAME
                            </th>
                          
                            <th class="text-left  green darken-4 white--text">
                                VIDEO TITLE
                            </th>
                            <th class="text-left  green darken-4 white--text" v-if="ADMIN.role == 'SUPER ADMIN'" >
                                VOTERS
                            </th>
                            </tr>
                        </thead>
                        
                        <tbody >
                            <tr
                                v-for="(item,index) in search()"
                                :key="index"
                                
                            >
                            <td class="text-left black--text"  v-if="searchText == ''"><h3 v-if="(index > 2)">{{ index+1 }}</h3>
                                <h3 v-else class="green--text"> <v-icon class="orange--text mr-2">mdi-trophy-variant</v-icon>{{ index + 1 }}</h3></td>
                                <td class="text-left black--text"  v-if="searchText != ''"><h3>{{ index+1 }}</h3></td>
                            <td class="text-left black--text" v-if="item.votes"><b>{{item.votes}}</b></td>
                            <td class="text-left black--text" v-else>0</td>
                            <td class="text-left black--text"><b>{{item.item.name.toUpperCase()}}</b></td>
                            <td class="text-left black--text" v-if="item.participant">{{item.participant.name.toUpperCase()}}</td>
                            <td class="text-left black--text" v-else>NA</td>
                          
                            <td class="text-left black--text" v-if="item.participant">{{item.participant.address.district}}</td>
                            <td class="text-left black--text" v-else>NA</td>
                           
                            <td class="text-left black--text" v-if="item.participant && item.participant.institution">{{item.participant.institution.institution_name}}</td>
                            <td class="text-left black--text" v-else>NA</td>
                           
                            <td class="text-left black--text">{{item.title}}</td>
                             <td class="text-left" v-if="ADMIN.role == 'SUPER ADMIN'"  >
                               
                                <div v-if="item.votes " class="green--text darken-4 text-left" text @click="gotoDetails(item._id,item.participant.name)"> <a class="green--text text--darken-4"><b>Voter Details</b></a></div>
                            </td>                    
                        </tr>
                         </tbody>
                        </template>
                  </v-simple-table>
                    <v-dialog
                        v-model="dialog"
                        max-width="1030"
                       max-height = "200"
                        v-if="option.url"
                        >
                        <v-row dense align="center" justify="center">
                            <v-col cols="12">
                                <v-card width="1000" class="black">
                                    <v-btn v-if="option.url" @click="closeVideo()" text>
                                        <v-icon  class="mr-3 mt-1 mb-2 red white--text" size="20">mdi-close</v-icon>
                                        <v-badge overlap  color="red"  class="mb-2" tile @click="closeVideo()" content="close video"></v-badge>
                                    </v-btn>
                                <Artplayer v-if="option.url" :option="option" :style="style" class="ml-5 " />
                             </v-card>
                            </v-col>
                           
                        </v-row>
                        
                    </v-dialog>
              </v-flex>
            </v-layout> 
                    
        </v-flex>
    </v-layout>
</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'
import { mapGetters } from 'vuex'

import Artplayer from "./ArtPlayer"


export default {
    data(){
        return {
            users : [],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
            dateOne: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menuOne: false,
            modalOne: false,
            menu2One: false,
            searchText:"",
            option: {
            url:"",
            fullscreen: true,
            theme:"green",
            autoplay: true,
            aspectRatio: true,
            rank :false
              },
            style: {
                width: "auto",
                height: "500px",
                // margin : " 0 0 0 20px"
            },
            dialog: false,
            division: [
                    { title: 'Lower Primary' },
                    { title: 'Upper Primary' },
                    { title: 'High School' },
                    { title: 'Higher Secondary' },
                    { title: 'University' }
                ],
            events: [],
            votes : []
        }
    },
    async mounted(){
    
        {
            let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/leaderboard?division=" + this.$route.params.division,
            headers: {
                'Authorization': this.TOKEN
            }
         })
            this.users = data.leaderboard
        }
        {
            let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/item",
            headers: {
                'Authorization': this.TOKEN
            }
         })
            this.events = data
        }                
    },
    computed:{
        ...mapGetters(['ADMIN','TOKEN'])
    },
    components: {
    Artplayer,
   
  },
    methods : {
        gotoDetails(id,name){
        this.$router.push({name:"Voters",params:{submission_id : id,participant :name}})
       },
    async deleteUser(id){
    swal({
                  title: "Are you sure?",
                  text: "The Submissions & Participant will be removed permanantly!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then( async (willDelete) => {
                  if (willDelete) {
                    let {data}  = await Axios({
                        method : 'delete',
                        url : BASE_URL + "/registration/delete/" + id,
                        headers: {
                            'Authorization': this.TOKEN
                        }
                    })
                   await swal("Deleted", "Submissions removed successfully!", {
                      icon: "success",
                    });
                  
                    this.$router.go('')
                  } else {
                    swal("The Participant was not deleted!");
                  }
                })
                
      
    },
    async deleteSubmission(id){
    swal({
                  title: "Are you sure?",
                  text: "The Submissions & Participant will be removed permanantly!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then( async (willDelete) => {
                  if (willDelete) {
                    let {data}  = await Axios({
                        method : 'delete',
                        url : BASE_URL + "/submission/delete/" + id,
                        headers: {
                            'Authorization': this.TOKEN
                        }
                    })
                   await swal("Deleted", "Submissions removed successfully!", {
                      icon: "success",
                    });
                   
                    this.$router.go('')
                  } else {
                    swal("The Participant was not deleted!");
                  }
                })
                
      
    },
    search(){
        console.log(this.searchText);
             if(this.searchText){
                console.log(this.searchText);
                    return this.users.filter((item)=>{
                        if(item.participant.name){
                            return ( this.searchText.toLowerCase().split(' ').every(v => item.participant.name.toLowerCase().includes(v))
                              
                    )
                        }
                    })
                  }
                  else{
                    return this.users;
                  
                  }
                },
   
 }
}
</script>

<style scoped>
   
  thead{
    background-image: linear-gradient(266deg, #043946 0%, #8d71cf 100%);

    }
    .button{
        background-image: linear-gradient(266deg, #066d14 0%, #030f03 100%);
    }
    .table {
  background-color: red;}
 
</style>>


import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueToastr from 'vue-toastr'
import CKEditor from 'ckeditor4-vue';
import Toasted from 'vue-toasted';
import swal from 'sweetalert';
import VueCoreVideoPlayer from 'vue-core-video-player'
import moment from 'moment'

Vue.config.productionTip = false
// Vue.filter('formatDate', function(value) {
//   if (value) {
//     return moment(String(value)).format('DD/MM/YYYY HH:MM')
//   }
// });
Vue.use(require('vue-moment'));
Vue.use(VueCoreVideoPlayer)
 
Vue.use(Toasted,
  {duration:3000,
    theme:'outline',
    position:'top-center'
  })
Vue.use(VueToastr);
Vue.use( CKEditor );

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

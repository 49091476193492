<template>
  <v-layout row wrap style="height:100%" >
        <v-img
        max-width="100%"
        relative
        >
    <v-card
    class="ma-auto mt-10 pa-3"
    max-width="1400"
    elevation="6"
    >
    <v-layout row wrap>
      <v-flex xs12 md6>
        <v-hover v-slot="{ hover }">
        <v-card class="image"  :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }">
        <v-img
        :src="images"
        relative
        aspect-ratio="1"
        >
        <v-btn absolute 
          bottom left xs12 md12 @click="login" small rounded outlined class="yellow" max-width="200"> <b> Login</b></v-btn>
        </v-img>
        
    </v-card>
  </v-hover>
      </v-flex>
      <v-flex xs12 md6>
        <v-card elevation="0" class="mt-12">
      <v-card-title class="justify-center" >
     <h3 class="green--text">
      CREATE ACCOUNT
     </h3> 
    </v-card-title>
        <v-container fluid style="max-width:500px"> 
              <v-row class="ma-2">
                  <v-col cols="12" md="6">
                    <v-text-field color="green"  outlined autofocus v-model="username" label="Username" clearable > 
                  </v-text-field>
                </v-col>
                  <v-col  cols="12" md="6">
                    <v-text-field color="green" outlined v-model="phone" label="Phone" clearable> 
                  </v-text-field>
                </v-col>
               <v-col  cols="12" md="4">
                  <v-select color="green" required single-line outlined v-model="role" :items="items" label="Role"> 
                  </v-select>
               </v-col>
                  <v-col  cols="12" md="8">
                  <v-text-field color="green"  outlined v-model="email" label="email" type="email" clearable> 
                </v-text-field>
               </v-col>
               <v-col cols="12" md="12">
                  <v-text-field color="green"  outlined v-model="password" label="Password" type="password" clearable> 
                  </v-text-field>
               </v-col>
               <v-col cols="12" md="12">
               <v-checkbox
               md12
               label=" I agree with the terms and conditions"
               v-model="checkbox"
               value:submit
               color="success"
               >
               </v-checkbox>
               </v-col>
              <v-col cols="12" md="12" >
               <v-btn block xs12 md12 @click.prevent="signUp" :disabled="checkbox == ''" rounded large class="green white--text">Sign Up</v-btn>
               </v-col>
               </v-row>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>
    </v-img>
    </v-layout>     
</template>

<script>
import Axios from 'axios'
import { BASE_URL } from '../config'
import { mapGetters } from 'vuex'


export default {
    data(){
         return{
             username:"",
             phone:"",
             email:"",
             password : "",
             district:"",
             checkbox:"",
             items: [
                     "SUPER ADMIN",
                     "ADMIN",
                     "USER"
                    ],
             role:"",
             msg:[],
             images:require('../assets/signup3.jpg'),
            //  image:require('../assets/signup2.jpg')

         }
         
     },
     computed:{
        ...mapGetters(['ADMIN','TOKEN'])
    },
     methods : {
                  validateEmail() {
                      },
                  login(){
                      this.$store.dispatch('SET_SIGNUP',false)
                      this.$router.push('/login')
                  },

        async signUp(){
         
          //email validation method
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
            if(this.checkbox == true) {
                            let payload = 
                                    { name : this.username,
                                      phone : this.phone,
                                      role : this.role,
                                      email : this.email,
                                      password : this.password,
                                    }
                                          let url = BASE_URL + "/admin"
                                          let {data}  = await Axios.post(url,payload,{ headers: {'Authorization': this.TOKEN }})
                                         
                              
                            //           .then((response) => {
                                          
                            //       if(response.data.msg === "USER CREATED SUCCESSFULLY"){
                            //         this.$toasted.success(response.data.msg )
                            //         this.$store.dispatch('SET_SIGNUP',false)
                            //         this.$store.dispatch("SET_CHANGEPASSWORD",this.email)
                            //         this.$router.push('/login')
                            //       }
                            //     else this.$toasted.error(response.data.msg )
                            // })
                            .catch((err) => {    
                                console.log(err)    
                            })  
                            this.$toasted.success('User Created Successfully')
                            this.$router.push('./carousel')
                       
                         }
                      } 
                      else {
                             this.$toasted.error('Please enter a valid email address');
                      }
            
         }  
     }
}
</script>

<style scoped>
.image{
  transition: opacity .2s ease-in-out;
}

.image.on-hover {
  opacity: .9;
 }

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>

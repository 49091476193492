<template >
    <v-layout row wrap style="height:100%;" >
        <v-img
        relative
        max-width="100%"
        >
        <v-card
        class="ma-auto mt-10 pa-3"
        max-width="1400"
        elevation="6"
        >
    <v-layout row wrap>
      <v-flex xs12 md6>
        <v-hover v-slot="{ hover }">
        <v-card class="image"  :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }">
        <v-img
        :src="images"
        relative
        aspect-ratio="1"
        
        >
        <v-btn absolute
          bottom left xs12 md12 @click="signUp"  rounded small color="#80CBC4" max-width="200" class="ma-2" > <b> Signup</b></v-btn>
        </v-img>
        
    </v-card>
  </v-hover>
      </v-flex>
      <v-flex xs12 md6>
        <v-card class="mt-12" elevation="0">
      <v-card-title class="justify-center" >
     <h3 class="green--text mt-12 ">
        BALARAMA STARS 2022
     </h3> 
    </v-card-title>
        <v-container fluid style="max-width:400px"> 
              <v-row dense>
                <v-col  cols="12" md="12" color="green">
                        <v-text-field color="green" outlined autofocus v-model="email" label="email" type="email" prepend-inner-icon="mdi-email" clearable> 
                        </v-text-field>
                     </v-col>
                 <v-col cols="12" md="12" >
                     <v-text-field color="green" outlined v-model="password"  label="Password" type="password" @keydown.enter="login" prepend-inner-icon="mdi-lock-open" clearable> 
                     </v-text-field>
                  </v-col>
                    <v-col  cols="12" md="12" color="green">
                    </v-col>
                    <v-col cols="12" md="12" align="center">
                    </v-col>
              <v-col cols="12" md="12" >
               <v-btn block xs12 md12 @click="login" rounded large class="green white--text">Login</v-btn>
               </v-col>
               </v-row>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>   
        </v-img>
     </v-layout>
 </template>
 <script>
  import Axios from 'axios'
  import { BASE_URL } from '../config'

 export default {
     data(){
         return{
             email:"",
             password : "",
             select:null,
            //  image:require('../assets/stars.jpg'),
             images:require('../assets/login2.jpg')

         }
     },
     methods : {
        async login(e){
            //email validation method
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
            e.preventDefault()
                
                let payload = 
                            {
                            email : this.email,
                            password : this.password,
                            }
                           
                let url = BASE_URL + "/admin/login"
                let {data} = await Axios.post(url,payload)
                            .then((response) => {        
                       
                              if(response.data.success){
                                this.$toasted.success(response.data.msg)
                                this.$store.dispatch("SET_TOKEN",response.data)
                                this.$router.push("/carousel")    
                                this.$store.dispatch("SET_LOGIN",true)
                               
                              }
                              else{
                                this.$toasted.error(response.data.msg)
                              }
                               
                            })    
                            .catch((err) => {    
                                console.log(err)    
                            })       
            }
            else{
                this.$toasted.error('Please enter a valid email address')
        }
    },
            signUp(){
                this.$store.dispatch("SET_SIGNUP",true)
                this.$router.push('/signup') 
            }
    }
}
 </script>
<style scoped>
.image{
  transition: opacity .2s ease-in-out;
}

.image.on-hover {
  opacity: .9;
 }

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
} 

</style>
import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../components/Login'
import SignUp from '../components/SignUp'
import ChangePassword from '../components/ChangePassword'
import Registration from '../components/Registration'
import Home from '../views/Home'
import UserDetails from '../components/UserDetails'
import Carousel from '../components/Carousel'
import Banner from '../components/Banner'
import Cards from '../components/Cards'
import Submission from '../components/Submission'
import LeaderBoard from '../components/LeaderBoard'
import Voters from '../components/Voters'
import SearchSubmissions from '../components/SearchSubmissions'
import SearchRegistrations from '../components/SearchRegistrations'




Vue.use(VueRouter)

const routes = [

  {
    path : "/dashboard",
    name : "Home",
    component : Home
  },
  {
    path : "/",
    name : "login",
    component : Login
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/userdetails/:id',
    name: 'UserDetails',
    component: UserDetails
  },
  {
    path: '/carousel',
    name: 'Carousel',
    component: Carousel
  },
  {
    path: '/banner',
    name: 'Banner',
    component: Banner
  },
  {
    path: '/cards',
    name: 'Cards',
    component: Cards
  },
  {
    path: '/submission',
    name: 'Submission',
    component: Submission
  },
  {
    path: '/leaderboard/:division',
    name: 'LeaderBoard',
    component: LeaderBoard
  },
  {
    path: '/voters/:submission_id/:participant',
    name: 'Voters',
    component: Voters
  },
  {
    path: '/search-submission',
    name: 'searchSubmissions',
    component: SearchSubmissions
  },
  {
    path: '/search-registrations',
    name: 'searchRegistrations',
    component: SearchRegistrations
  },
]

var router = new VueRouter({
  routes,
})

export default router

<template>
    <v-layout row wrap >
        <v-flex xs12 md12 class="ml-2">
            <div class="white mt-6 ">
                    <h1 class="text-center">
                        <b>VOTERS DETAILS</b> 
                        <h4 class="grey--text text--darken-2">{{$route.params.participant}} - ({{voters[0].participant.division}})</h4>
                        <h5 class="brown--text">TOTAL VOTES : <span class="black--text"><b>{{voters.length}}</b> </span></h5>
                        </h1>
                    </div>     
              <v-layout row-wrap class="mx-10 ">
                <v-flex xs12 md12>
                <v-simple-table class="ma-2" fixed-header dense>
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left lime darken-3 ">
                               <b>#</b> 
                            </th>
                            <th class="text-left lime darken-3 ">
                               <b>NAME</b> 
                            </th>
                            <th class="text-left lime darken-3 ">
                               <b>PHONE</b> 
                            </th>
                            <th class="text-left  lime darken-3 ">
                               <b>LOCALITY</b> 
                            </th>
                            <th class="text-left  lime darken-3 ">
                              <b>DISTRICT</b>  
                            </th>
                            <th class="text-left  lime darken-3 ">
                              <b>DEVICE ID</b> 
                            </th>
                            <th class="text-left  lime darken-3 ">
                               <b>POST OFFICE</b> 
                            </th>
                             <th class="text-left  lime darken-3 " >
                              <b>PIN CODE</b>  
                            </th>
                            
                            </tr>
                        </thead>
                        
                        <tbody >
                            <tr
                                v-for="(item,index) in voters"
                                :key="index"
                                
                            >
                            <td class="text-left black--text" ><h3>{{ index+1 }}</h3></td>
                            <td class="text-left black--text" >{{item.vote_added_by.name}}</td>
                            <td class="text-left black--text" >{{ item.vote_added_by.phone }}</td>
                            <td class="text-left black--text" >{{ item.vote_added_by.locality }}</td>
                            <td class="text-left black--text" >{{ item.vote_added_by.district }}</td>
                            <td class="text-left black--text" >{{ item.vote_added_by.device_id }}</td>
                            <td class="text-left black--text" >{{ item.vote_added_by.po }}</td>
                            <td class="text-left black--text" >{{ item.vote_added_by.pincode }}</td>
                            
                        </tr>
                         </tbody>
                        </template>
                  </v-simple-table>
                    
              </v-flex>
            </v-layout> 
                    
        </v-flex>
    </v-layout>
</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'
import { mapGetters } from 'vuex'



export default {
    data(){
        return {
            searchText :"",
            voters : [],
        }
    },
    async mounted(){
        {
            let {data}  = await Axios({
            method : 'get',
            url : BASE_URL + "/votes/" + this.$route.params.submission_id,
            headers: {
                'Authorization': this.TOKEN
            }
         })
         console.log(data);
            this.voters = data
        }
              
    },
    computed:{
        ...mapGetters(['ADMIN','TOKEN'])
    },
   
   
 
   
}
</script>

<style scoped>
   
  thead{
    background-image: linear-gradient(266deg, #043946 0%, #8d71cf 100%);

    }
    .button{
        background-image: linear-gradient(266deg, #066d14 0%, #030f03 100%);
    }
    .table {
  background-color: red;}
 
</style>>


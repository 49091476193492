<template>
  <div >
<v-navigation-drawer
        class="expand"
        app
        dark
        temporary
        v-model="drawer"
        
       >
      <!-- <v-icon @click="drawer = !drawer" color="green">menu</v-icon> -->
        <v-list dense>
          <template v-for="item in items">
            <v-row
              v-if="item.heading"
              :key="item.heading"
              align="center"
            >
              <v-col cols="6">
                <v-subheader v-if="item.heading ">
                 {{ item.heading }}
                </v-subheader>
              </v-col>
            </v-row>
            <v-list-group
              v-else-if="item.children"
              :key="item.text"
              v-model="item.model"
             
            >
              <template v-slot:activator >
                <v-list-item-content >
                  <v-list-item-title>
                    <b>{{ item.text }}</b>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(child, i) in item.children"
                :key="i"
                class="lighten-4 ml-6"
                link
                @click="selectItem(child.text)"
                active-class="green"

              >
                <v-list-item-action v-if="child.icon ">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
            active-class="green"
              v-else
              :key="item.icon"
              link
              @click="selectItem(item.text)"
            >
            <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
        <v-app-bar xs12 md12
        app
        dark
        extended extension-height="8"
        v-if="LOGIN"
      
        height="40"
      >
        <v-icon @click ="drawer = !drawer" color="green" class="mt-2">menu</v-icon>
        <v-toolbar-title
          style="width: 300px"
          class="ml-0 pl-4"
        >
        <!-- <v-icon color="green">mdi-home</v-icon> -->
        <a ><span class="hidden-sm-and-down"><img src="../assets/logo-3.png" style="width:70px" class="mt-4" @click="goHome"/></span></a>
      </v-toolbar-title>
        <!-- <p class="mt-3 mx-2 mb-2 white--text">Welcome, {{ ADMIN.name }}</p> -->
        <v-spacer></v-spacer>
        <v-btn @click="logOut" class="mt-3 mx-2 mb-2 green--text" text>
        LOGOUT
       </v-btn>
       <!-- <v-btn @click="Signup()" class="mt-3 mx-2 mb-2 green--text" text>
        SIGNUP
       </v-btn> -->
      </v-app-bar>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
  export default {
    computed : {
      ...mapGetters(['PROGRESS','USER','LOGIN','ADMIN'])
    },
    data: () => ({
      dialog: false,
      drawer: false,
      // images:require('../assets/iu12.jpg'),
      panel: [0, 1],
      disabled: false,
      readonly: false,
      items: [
        
        {
          icon: 'mdi-home',
          'icon-alt': 'home',
          text: 'Home',
          model: false,
        },
        {
          icon: 'mdi-account-box-multiple',
          'icon-alt': 'home',
          text: 'Registered Users',
          model: false,
        },
        // {
        //   icon: 'mdi-form-textbox-password',
        //   'icon-alt': 'home',
        //   text: 'Change Password',
        //   model: false,
        // },      
        {
          icon: 'mdi-video',
          'icon-alt': 'home',
          text: 'Submissions',
          model: false,
        }, 
        {
          icon: '',
          'icon-alt': 'home',
          text: 'LeaderBoard',
          children :[{
            icon : "mdi-trophy",
            text : "Lower Primary"
          },
          {
            icon : "mdi-trophy-outline",
            text : "Upper Primary"
          },
          {
            icon : "mdi-trophy-broken",
            text : "High School"
          },
          {
            icon : "mdi-trophy-award",
            text : "Higher Secondary"
          },
          {
            icon : "mdi-trophy-variant-outline",
            text : "University"
          }
        ] ,
          model: false,
        }, 
         {
          icon: 'mdi-card-text',
          'icon-alt': 'home',
          text: 'Cards',
          model: false,
        }, 
        {
          icon: 'mdi-account-multiple-plus',
          'icon-alt': 'home',
          text: 'Sign up',
          model: false,
        }, 
        {
          icon: 'mdi-logout',
          'icon-alt': 'power_settings_new',
          text: 'Logout',
          model: false,
        },
      ]
    }),
    methods : {
      selectItem(item){
        
        if(item == "Home"){
            this.$router.push('/carousel')}

        if(item == "Registered Users"){
            this.$router.push('/registration')}

        if(item == "Change Password"){
            this.$router.push('/change-password')}

        if(item == "Sign up" && this.ADMIN.role == 'SUPER ADMIN'){
            this.$router.push('/signup')}

        if(item == "Submissions"){
            this.$router.push('/submission')} 
          
        if(item == "Lower Primary"){
            this.$router.push({name : "LeaderBoard",params: {division : "Lower Primary"}})
            this.$router.go("")
        }
        if(item == "Upper Primary"){
            this.$router.push({name : "LeaderBoard",params: {division : "Upper Primary"}}) 
            this.$router.go("")

        }
        if(item == "High School"){
            this.$router.push({name : "LeaderBoard",params: {division : "High School"}})
            this.$router.go("")

        }
        if(item == "Higher Secondary"){
            this.$router.push({name : "LeaderBoard",params: {division : "Higher Secondary"}})
            this.$router.go("")

        }
        if(item == "University"){
            this.$router.push({name : "LeaderBoard",params: {division : "University"}})
            this.$router.go("")

        }
       
        if(item == "Cards" && this.ADMIN.role == 'SUPER ADMIN'){
            this.$router.push('/cards')}

        if(item == "Logout"){
          this.$store.dispatch("SET_LOGIN",false)
          this.$router.push('/login')

        }
      
      
      },
     
      goHome(){
            this.$router.push('/carousel')
      },
      logOut(){
        this.$store.dispatch('SET_LOGIN',false)
        this.$router.push('/login')
        
      },
    //  Hello(){
    //   alert('login')
    //     // this.$router.push('/login')
    //     // this.$store.dispatch('SET_LOGIN',false)

    //   },
      Signup(){
        this.$router.push('/signup')
      }
   
    }
    
  }
</script>

<style scoped>
.expand:hover{
  /* background: url('../assets/iu12.jpg'); */
  /* background-size: 260px 980px  */

  /* background-image: linear-gradient(to left,#C8E6C9 , #81C784); */
  /* color: #000; */
}
</style>